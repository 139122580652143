<template>
  <v-card height="100%">
    <v-card-title class="justify-space-between">
      <div class="card-title ma-0 body-2 text-uppercase blue--text">
        {{ t("gold_status.title") }}
      </div>
    </v-card-title>
    <v-card-text class="ps-4 pe-4">
      <div class="d-flex flex-column justify-center align-center">
        <v-img :src="currentLevelMark" lt="" width="50" />
      </div>
      <div class="text-center">
        <p class="ma-0">{{ t("gold_status.current_status") }}</p>
        <h6 class="mb-3">{{ t(`gold_status.levels.level_${currentLevel.id}`, { percent: currentLevel.percent }) }}</h6>

        <v-chip small class color="green lighten-5" text-color="black">
          <v-avatar left>
            <v-icon color="green lighten-1" small>mdi-trending-up</v-icon>
          </v-avatar>
          <span class=" text--lighten-1">{{ t("gold_status.efficiency", { value: user.efficiency }) }}</span>
        </v-chip>

        <v-chip small class="amber lighten-5 ml-2" text-color="black">
          <v-avatar left>
            <v-icon color="amber  lighten-1" small>mdi-database</v-icon>
          </v-avatar>
          <span class="amber-text text--lighten-1">{{ t("gold_status.coins", { value: user.coins }) }}</span>
        </v-chip>
      </div>

      <div class="text-center">
        <p class="mt-3 mb-0">{{ nextLevelConditionText }}</p>
        <v-icon v-if="nextLevel" color="danger" class="mb-3">mdi-arrow-down</v-icon>
      </div>
      <template v-if="nextLevel">
        <div class="d-flex flex-column justify-center align-center">
          <v-img :src="nextLevelMark" lt="" width="40" />
        </div>

        <div class="text-center">
          <p class="ma-0 caption text-gray-500">{{ t("gold_status.next_status") }}</p>
          <p class="text-gray-500">
            {{ t(`gold_status.levels.level_${nextLevel.id}`, { percent: nextLevel.percent }) }}
          </p>
        </div>
      </template>
      <div v-else class="d-flex flex-column justify-center align-center">
        <v-img src="@/assets/images/gold-status/cap.png" lt="" width="100" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
/**
 * Блок Gold статуса
 */
export default {
  props: {
    /**
     * Данные о пользователе (монеты и эффективность)
     */
    user: { type: Object, default: null },

    /**
     * Данные текущего уровня
     */
    currentLevel: { type: Object, default: null },

    /**
     * Данные следующего уровня
     */
    nextLevel: { type: Object, default: null }
  },

  computed: {
    /**
     * Изображение медальки для текущего уровня
     */
    currentLevelMark() {
      return require(`@/assets/images/gold-status/${this.currentLevel.id}.png`);
    },

    /**
     * Изображение медальки для следующего уровня
     */
    nextLevelMark() {
      return require(`@/assets/images/gold-status/${this.nextLevel.id}.png`);
    },

    /**
     * Текст для условия перехода на следущий уровень
     */
    nextLevelConditionText() {
      // Если это уровень Standard
      if (this.currentLevel.id == 0) {
        if (this.user.efficiency >= 0) {
          return this.t("gold_status.next_level_condition.wait_next_period");
        } else {
          const needEfficiency = -this.user.efficiency;
          return this.t("gold_status.next_level_condition.increase_efficiency", { value: needEfficiency });
        }
      }

      // Если достигнут наивысший уровень
      if (this.nextLevel == null) {
        return this.t("gold_status.next_level_condition.maximum_level");
      }

      // Остальные уровни
      const needCoins = this.nextLevel.minimal - this.user.coins;
      return this.t("gold_status.next_level_condition.need_more_coins", { value: needCoins });
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.earnings." + key, params);
    }
  }
};
</script>
