<template>
  <div>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-text class="v-card__text--dense">
            <div class="d-flex justify-space-between align-center">
              <div class="card-title ma-0 text-capitalize ">
                {{ $t(`app.sections.${currentRouteName}`) }}
              </div>
              <v-breadcrumbs :items="routePath">
                <template #divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </div>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <today-earnings></today-earnings>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="activeGoldProgram && fullWidth ? 8 : 12">
        <v-card height="100%">
          <v-card-title class="justify-space-between">
            <div class="card-title ma-0 body-2 text--disabled text-uppercase">{{ t("filters.title") }}</div>
            <v-spacer />
            <filters-block @update="onFilterUpdate"></filters-block>
          </v-card-title>
          <v-card-text class="ps-4 pe-4">
            <chart :raw-data="items"></chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="activeGoldProgram && fullWidth" cols="4">
        <gold-status
          v-if="userData"
          :user="userData"
          :current-level="currentLevelData"
          :next-level="nextLevelData"
        ></gold-status>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-space-between">
            <div class="card-title ma-0 body-2 text--disabled text-uppercase">{{ t("earnings_table.title") }}</div>
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :items-per-page="options.itemsPerPage"
              :loading="loading"
              :footer-props="footerProps"
              item-key="date"
              @update:options="onOptionsUpdate"
            >
              <template slot="body.append">
                <tr v-if="additional && !$vuetify.breakpoint.xs" class="font-weight-bold">
                  <td>Total</td>
                  <td>{{ additional.file_sum }}</td>
                  <td>{{ additional.site_sum }}</td>
                  <td>{{ additional.ref_sum }}</td>
                  <td>{{ additional.bonus_sum }}</td>
                  <td>{{ additional.total_sum }}</td>
                  <td v-if="activeGoldProgram"></td>
                  <td v-if="activeGoldProgram"></td>
                </tr>
              </template>
            </v-data-table>
            <div class="body-2" v-html="t('notes.additional')"></div>
            <div
              v-if="activeGoldProgram"
              class="body-2"
              v-html="
                t('notes.efficiency', {
                  efficiency_url: '/faq#' + efficiencyFaqId,
                  gold_url: '/faq#' + efficiencyFaqId,
                  faq_url: '/faq'
                })
              "
            ></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <gold-status
      v-if="activeGoldProgram && userData && !fullWidth"
      :user="userData"
      :current-level="currentLevelData"
      :next-level="nextLevelData"
    ></gold-status>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataTable from "@/views/mixins/dataTable";
import todayEarnings from "./TodayEarnings";
import filtersBlock from "./Filters";
import chart from "./Chart";
import goldStatus from "./GoldStatus";

/**
 * Страница с заработком
 */
export default {
  components: {
    todayEarnings,
    filtersBlock,
    chart,
    goldStatus
  },
  metaInfo() {
    return {
      title: this.$t("titles.earnings")
    };
  },
  mixins: [dataTable],
  data() {
    return {
      filters: {},
      userData: null,
      currentLevelData: null,
      nextLevelData: null
    };
  },

  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),

    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app.toLowerCase();
    },

    /**
     * Активность Gold программы
     */
    activeGoldProgram() {
      return this.appType == "fd1";
    },

    /**
     * Имя текущей секции
     */
    currentRouteName() {
      return this.$route.name;
    },

    user() {
      return this.$store.state.auth.userData;
    },

    efficiencyFaqId() {
      return this.user.lang === "ru" ? 29 : 64;
    },

    /**
     * Отображать ли вариант для полной ширины страницы
     */
    fullWidth() {
      const { lg, xl } = this.$vuetify.breakpoint;
      return lg || xl;
    },

    /**
     * Колонки таблицы
     */
    headers() {
      let columns = [
        {
          text: this.t("earnings_table.fields.date"),
          value: "id",
          sortable: true,
          width: "10em"
        },
        {
          text: this.t("earnings_table.fields.files"),
          value: "file_sum",
          sortable: true
        },
        {
          text: this.t("earnings_table.fields.sites"),
          value: "site_sum",
          sortable: true
        },
        {
          text: this.t("earnings_table.fields.referrals"),
          value: "ref_sum",
          sortable: true
        },
        {
          text: this.t("earnings_table.fields.additional"),
          value: "bonus_sum",
          sortable: true
        },
        {
          text: this.t("earnings_table.fields.total"),
          value: "total_sum",
          sortable: true
        }
      ];

      if (this.activeGoldProgram) {
        columns.push({
          text: this.t("earnings_table.fields.efficiency"),
          value: "ebitda",
          sortable: true
        });
        columns.push({
          text: this.t("earnings_table.fields.coins"),
          value: "coins",
          sortable: true
        });
      }

      columns.push({ text: "", value: "data-table-expand" });
      return columns;
    },

    /**
     * Данные для хлебных крошек
     */
    routePath() {
      return [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          href: "/"
        },
        {
          text: this.appTitle(this.appType),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("app.sections.earnings"),
          disabled: true,
          href: "/"
        }
      ];
    }
  },

  watch: {
    /**
     * Если изменился apptype
     */
    appType() {
      this.clearOptions();
      this.loadGoldStatusData();
    }
  },

  mounted() {
    this.loadGoldStatusData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.earnings." + key, params);
    },

    /**
     * Сброс условий к значениям по-умолчанию. Приведет к перезагрузке данных с сервера
     */
    clearOptions() {
      this.options.page = 1;
      this.options.itemsPerPage = 25;
      this.options.sortBy = ["id"];
      this.options.sortDesc = [true];
    },

    /**
     * Формирование параметров для запроса данных
     */
    getLoadingParams() {
      let data = {
        app: this.appType
      };

      // Обработка фильтров
      ["from", "to", "group"].forEach(name => {
        if (this.filters[name]) {
          data[name] = this.filters[name];
        }
      });

      return Object.assign(this.getBaseParams(), data);
    },

    /**
     * Загрузка данных по датам
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("earnings/loadEarningsData", this.getLoadingParams())
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },

    /**
     * Реакция на изменение фильтра
     */
    onFilterUpdate(data) {
      this.filters = data;
      if (this.options.page != 1) {
        this.oldPageValue = this.options.page = 1;
      } else {
        this.loadData();
      }
    },

    /**
     * Загрузка данных о Gold статусе пользователя
     */
    loadGoldStatusData() {
      this.$store.dispatch("earnings/loadGoldStatusData", { app: this.appType }).then(this.processGoldStatusData);
    },

    /**
     * Обработка данных о статусе пользователя
     * @param response
     */
    processGoldStatusData(response) {
      this.userData = response.data.data;
      this.currentLevelData = response.data.current;
      this.nextLevelData = response.data.next;
    }
  }
};
</script>
