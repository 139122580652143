<template>
  <apexchart type="line" height="350" :options="options" :series="series" />
</template>

<script>
/**
 * График видов заработка
 */
export default {
  props: {
    rawData: {
      type: Array,
      default: new Array()
    }
  },
  data() {
    return {
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: ["#edeff4", "#f05a27", "#4ca8ff", "#83cfff"],
        stroke: {
          curve: "smooth",
          width: [2, 8, 2, 2]
        },
        markers: {
          size: [0, 5, 0, 0],
          hover: { sizeOffset: 2 }
        },
        title: {
          text: ""
        },
        xaxis: {
          type: "category",
          labels: {
            offsetY: this.$vuetify.rtl ? 50 : 0
          }
        },
        yaxis: []
      }
    };
  },

  computed: {
    series() {
      let total = [];
      let files = [];
      let sites = [];
      let referrals = [];

      this.rawData.forEach(row => {
        total.push({
          x: row.id,
          y: row.total_sum
        });
        files.push({
          x: row.id,
          y: row.file_sum
        });
        sites.push({
          x: row.id,
          y: row.site_sum
        });
        referrals.push({
          x: row.id,
          y: row.ref_sum
        });
      });

      return [
        {
          name: "Total",
          type: "area",
          data: total.sort(this.compareNodes)
        },
        {
          name: "Files",
          type: "line",
          data: files.sort(this.compareNodes)
        },
        {
          name: "Sites",
          type: "area",
          data: sites.sort(this.compareNodes)
        },
        {
          name: "Referrals",
          type: "area",
          data: referrals.sort(this.compareNodes)
        }
      ];
    }
  },

  methods: {
    /**
     * Сортировка значений узлов графика по координате "y" (дате)
     * @param a
     * @param b
     * @return {number}
     */
    compareNodes(a, b) {
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return 1;
      }
      return 0;
    }
  }
};
</script>
